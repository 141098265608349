export type CheckboxVariant = keyof typeof VARIANTS;
export type CheckboxSize = keyof typeof SIZE;

export const VARIANTS = {
  filled: {
    border: "2px solid $primary-text",
  },
  borderless: {
    border: "none",
    background: "transparent",
  },
};

export const CHECKED = {
  true: {
    borderColor: "transparent",
    background: "$primary",
    color: "$primary",
  },
};

export const SIZE = {
  base: { height: "1rem" },
  large: { height: "1.5rem" },
};

export const COMPOUND_VARIANTS = [
  {
    checked: "true",
    variant: "borderless",
    css: {
      background: "transparent",
      color: "$neutral-black",
    },
  },
  {
    checked: "false",
    variant: "filled",
    size: "large",
    css: {
      borderWidth: "2px",
    },
  },
];
