import React, { useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

/* Components */
import { Label } from "@components/shared";
import { CheckboxInput, CheckboxContainer } from "./styled";

/* Types */
import type { CheckboxSize, CheckboxVariant } from "./types";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = {
  children?: React.ReactNode;
  wrapperClassName?: string;
  variant?: CheckboxVariant;
  size?: CheckboxSize;
  label?: string;
  labelAfter?: boolean;
  onChange?: (checked: boolean) => void;
} & Omit<InputProps, "onChange" | "size">;

export const Checkbox: React.FC<Props> = (props) => {
  const { children, containerProps, onChange, wrapperClassName, rest } =
    DefaultProps(props);

  const [checked, setChecked] = React.useState(rest.defaultChecked);

  useEffect(() => {
    setChecked(rest.checked);
  }, [rest.checked]);

  return (
    <fieldset
      role="button"
      tabIndex={0}
      onClick={() => {
        setChecked(!checked);
        onChange && onChange(!checked);
      }}
      className={`flex items-center gap-12 px-4 py-2 ${wrapperClassName}`}
    >
      {!props.labelAfter && <Label label={rest.label} htmlFor={rest.name} />}
      <CheckboxContainer
        size={props.size}
        onClick={() => {
          setChecked(!checked);
          onChange && onChange(!checked);
        }}
        {...containerProps}
        checked={checked && "true"}
      >
        {checked && (
          <CheckIcon
            fill="white"
            width={props.size === "base" ? "1rem" : "1.5rem"}
            height={props.size === "base" ? "1rem" : "1.5rem"}
          />
        )}
      </CheckboxContainer>
      {props.labelAfter && <Label label={rest.label} htmlFor={rest.name} />}
      <CheckboxInput type="checkbox" value={String(checked)} {...rest} />
      {children}
    </fieldset>
  );
};

const DefaultProps = (props: Props) => {
  const {
    children,
    wrapperClassName,
    variant = "filled",
    className,
    style,
    onChange,
    size = "base",
    labelAfter = false,
    ...rest
  } = props;

  const defaultProps = {
    children,
    wrapperClassName,
    rest,
    onChange,
    containerProps: {
      variant,
      checked: rest.checked,
      className,
      style,
    },
  };

  return defaultProps;
};
